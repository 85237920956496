<template>
  <div>
    <el-dialog :visible.sync="dialogVisible" width="60%" :before-close="handleClose">
      <template slot="title">
        <div class="plugin-modal-header">
          <div class="plugin-info">
            <div class="plugin-img">
              <img :src="jiraIcon" alt="" />
            </div>
            <div class="plugin-name">
              <p>Jira</p>
              <span>Altassian</span>
            </div>
          </div>
        </div>
      </template>
      <div class="plugin-modal-body">
        <p>You're about to enable Jira</p>
        <div class="plugin-modal-login">
          <div>
            <p><b>Email</b></p>
          </div>
          <div style="margin-bottom: 10px">
            <el-input placeholder="Enter your Jira email..." v-model="tempConfig.email" />
          </div>
        </div>
        <div class="plugin-modal-configuration">
          <div>
            <p><b>API Key</b></p>
          </div>
          <div>
            <el-input placeholder="Enter your Jira API key..." v-model="tempConfig.apiKey" />
          </div>
        </div>
        <div class="plugin-modal-configuration">
          <div>
            <p><b>Base URL</b></p>
          </div>
          <div>
            <el-input
              placeholder='Enter the server url, i.e. "https://<your-domain>.atlassian.net/rest/api/latest/"'
              v-model="tempConfig.url"
            />
          </div>
        </div>
        <div class="plugin-modal-configuration">
          <div>
            <p><b>Configure JSON file</b></p>
          </div>
          <div>
            <!-- Upload button accept JSON file only -->
            <el-upload
              action="#"
              class="upload-demo"
              :on-change="handleFileChange"
              :on-remove="handleRemoveFile"
              :auto-upload="false"
              :file-list="fileList"
              :limit="1"
              accept=".json"
            >
              <div class="upload-wrapper">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text" style="margin-bottom: 0">
                  Drop file here or <em>click to upload</em> <br />
                  File with a size less than 25mb
                </div>
              </div>
            </el-upload>

            <!-- Upload button -->
            <div style="text-align: right">
              <el-button
                v-if="fileList.length > 0"
                :loading="isUploading"
                :disabled="isUploading"
                type="primary"
                @click="handleUploadFile"
                style="margin: 10px 0"
              >
                Upload
              </el-button>
            </div>

            <div v-if="tempConfig.fileUrl">
              <div>
                <p><b>JSON file URL</b></p>
              </div>
              <el-input v-model="tempConfig.fileUrl" disabled />
            </div>
          </div>
        </div>
      </div>
      <template slot="footer">
        <div>
          <el-button type="plain" @click="handleClose">Cancel</el-button>
          <el-button type="primary" @click="handleConfirm">Confirm</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import jiraIcon from "./assets/icons/jira.png";

export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    selectedPlugin: {
      type: Object,
    },
  },
  data() {
    return {
      jiraIcon,
      fileList: [],
      tempConfig: {
        email: "",
        apiKey: "",
        url: "",
        fileUrl: "",
      },
      isUploading: false,
    };
  },
  methods: {
    handleClose() {
      this.$confirm("Do you want to close this window?", "Warning", {
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        type: "warning",
      })
        .then(() => {
          this.$emit("updateDialogVisible", false);
        })
        .catch(() => {});
    },
    handleConfirm() {
      this.$confirm("Do you want to save the current settings ?", "Warning", {
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        type: "warning",
      }).then(() => {
        this.$emit("updatePluginConfig", this.tempConfig);
        this.$success("Settings saved successfully");
      });
    },
    handleFileChange(file, fileList) {
      this.fileList = fileList;
      if (fileList.length > 0) {
        this.selectedPlugin.config.fileUrl = fileList[0].url;
      }
    },
    handleRemoveFile(file, fileList) {
      this.fileList = fileList;
      this.selectedPlugin.config.fileUrl = "";
    },
    async handleUploadFile() {
      try {
        this.isUploading = true;
        this.virusScan();

        if (this.fileList.length > 0) {
          const file = this.fileList[0];
          if (file.raw.type !== "application/json") {
            this.$message.error("Please upload a JSON file");
            return;
          }
        }

        const brain = this.$store.state.brain;
        const resp = await this.$store.dispatch("GENAI_UPLOAD_PLUGIN", {
          files: this.fileList,
          brain,
        });
        console.log(resp);
        if (resp.success) {
          this.tempConfig.fileUrl = _.first(resp.data).url;
          this.$nextTick(() => {
            this.$message.success("File uploaded successfully");
          });
        } else {
          this.$message.error("Failed to upload file");
        }
        this.isUploading = false;
      } catch (error) {
        this.$message.error("Failed to upload file");
        this.isUploading = false;
      }
    },
    async virusScan() {
      const formData = new FormData();
      const file = this.fileList[0].raw;
      formData.append("file", file);
      this.$rest("post", "miniapp_virus_scan", formData)
        .then((result) => {
          // this one to handle error coming from network level that returned 200
          const unexpectedResult = result.is_infected === undefined && result.viruses === undefined;
          const isSafe = !result.is_infected;
          const virusDetected = !_.isEmpty(result.viruses);
          if (unexpectedResult || !isSafe || virusDetected) {
            const message =
              !isSafe || virusDetected
                ? `File ${file.name} contain suspicious malware, please try with other file.`
                : "Failed to process virus scan. Please try again or contact support.";

            this.$notify.error({
              title: "Error",
              position: "bottom-right",
              message,
            });
            this.fileList = this.fileList.filter((item) => item.name !== file.name);
          }
        })
        .catch((error) => {
          const defaultMessage = "An error occurred while processing the file.";
          const errorData = _.get(error, "response.data", {});

          let errorMessage = typeof errorData === "string" ? errorData : errorData.message;
          if (errorMessage) {
            const errors = errorData.errors;
            if (Array.isArray(errors)) {
              const details = errors.join(".\n");
              errorMessage += `.\nDetails:\n${details}`;
            }
          }

          this.$notify({
            type: "error",
            title: "Error",
            position: "bottom-right",
            message: errorMessage || defaultMessage,
          });
          this.fileList = this.fileList.filter((item) => item.name !== file.name);
        });
    },
  },
  created() {
    if (this.selectedPlugin) {
      this.tempConfig = this.selectedPlugin.config;
    }
  },
};
</script>

<style lang="scss">
@import "./assets/css/modal.scss";

.upload-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 180px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  color: #8c939d;
  cursor: pointer;
  transition: border-color 0.1s;

  &:hover {
    border-color: #409eff;
  }
}
</style>
