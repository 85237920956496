<template>
  <div>
    <div id="persona-header">
      <div style="margin-right: 10px"></div>
    </div>
    <div id="persona-body">
      <div class="persona-body-left">
        <ul class="persona-list">
          <li style="padding: 5px 0; border-bottom: 1px solid #ebeef5">
            <el-popover v-model="showNewPersonaPopover" placement="left" width="344">
              <el-input
                v-model="newPersonaName"
                autofocus
                @keyup.enter.native="create"
                @keyup.esc.native="showNewPersonaPopover = false"
              >
                <template slot="prepend">Persona name</template>
                <el-button slot="append" icon="el-icon-check" @click="create" />
              </el-input>
              <el-tooltip
                slot="reference"
                class="item"
                placement="bottom"
                content="Add new persona"
              >
                <el-button type="primary">Add new persona</el-button>
              </el-tooltip>
              <!-- Add new content -->
            </el-popover>
          </li>
          <li
            class="persona-item"
            :class="{ selected: selectedPersonaIndex === index }"
            :key="index"
            v-for="(persona, index) in sortedPersonaList"
            @click="setSelectPersona(index)"
          >
            <span>{{ persona.name }}</span>
            <!-- tooltip warning that have unsave personas -->
            <el-tooltip
              class="item"
              effect="dark"
              content="Unsaved changes"
              placement="right"
              v-if="unSavedChanges.includes(index)"
            >
              <i class="el-icon-warning" style="color: #e6a23c"></i>
            </el-tooltip>
          </li>
        </ul>
      </div>
      <div class="persona-body-right">
        <div
          class="meta-prompt persona-body-item"
          style="display: flex; align-items: end; justify-content: space-between"
        >
          <div>
            <div class="meta-prompt-title persona-body-title">
              <span>Name</span>
            </div>
            <el-input
              size="small"
              type="text"
              placeholder="Please input"
              v-model="selectedPersona.name"
              @change="handleChangePersonaTagName"
            />
          </div>
          <div>
            <el-button class="save-button" type="primary" :loading="saving" @click="saveChanges"
              >Save</el-button
            >

            <el-button
              class="save-button"
              type="danger"
              :loading="saving"
              @click="handleDeletePersona"
              v-if="!isDefaultPersona"
              >Delete</el-button
            >
          </div>
        </div>

        <div class="meta-prompt persona-body-item">
          <div
            class="meta-prompt-title persona-body-title"
            style="display: flex; align-items: center; gap: 20px"
          >
            <div style="height: 100%; padding-bottom: 5px">Temperature</div>
            <el-slider
              v-model="selectedPersona.temperature"
              :min="0"
              :max="2"
              :step="0.1"
              show-input
              style="width: 30%"
            ></el-slider>
          </div>
        </div>

        <div class="meta-prompt persona-body-item">
          <div
            class="meta-prompt-title persona-body-title"
            style="display: flex; align-items: center; gap: 20px"
          >
            <div style="height: 100%; padding-bottom: 5px">Chat History Limit</div>
            <el-slider
              v-model="selectedPersona.chatHistoryLimit"
              :min="0"
              :max="100"
              :step="1"
              show-input
              style="width: 30%"
            ></el-slider>
          </div>
        </div>

        <div class="persona-body-item">
          <div class="meta-prompt-title persona-body-title">
            <span>Stream Responses</span>
            <el-tooltip
              class="item"
              effect="dark"
              content="Enable streaming response for this persona."
              placement="right"
            >
              <i class="el-icon-info" style="color: grey; margin-left: 5px"></i>
            </el-tooltip>
            <el-switch v-model="selectedPersona.streaming_response" style="margin-left: 20px" />
          </div>
        </div>

        <div class="meta-prompt persona-body-item" style="max-width: 80%">
          <div class="meta-prompt-title persona-body-title">
            <span>Prompt</span>
            <el-tooltip
              class="item"
              effect="dark"
              content="The prompt that defines the chat bot's persona."
              placement="right"
            >
              <i class="el-icon-info" style="color: grey; margin-left: 5px"></i>
            </el-tooltip>
          </div>
          <el-input
            type="textarea"
            :rows="20"
            placeholder="Please input"
            v-model="selectedPersona.prompt"
          />
        </div>

        <div style="display: flex; gap: 25px">
          <div style="max-width: 50%">
            <div style="margin-bottom: 10px">Connect to Knowledge Base</div>
            <el-card style="border: 1px solid #ebeef5; display: inline-block; width: fit-content">
              <el-table
                :data="document_tags"
                style="width: 100%"
                border
                v-if="document_tags.length > 0"
                :key="selectedPersonaIndex"
              >
                <el-table-column label="Enable" align="center">
                  <template slot-scope="scope">
                    <el-checkbox
                      @change="handleTagChange(scope.row.name, $event)"
                      :checked="isPersonaTagsContain(scope.row.name)"
                    ></el-checkbox>
                  </template>
                </el-table-column>
                <el-table-column label="Category" prop="name" width="100" align="center">
                </el-table-column>
                <el-table-column label="Tags" width="200">
                  <template slot-scope="scope">
                    <div style="display: inline-block">
                      <el-select
                        placeholder="Select tags..."
                        multiple=""
                        v-model="selectedTags[scope.row.name]"
                        :disabled="!isPersonaTagsContain(scope.row.name)"
                      >
                        <el-option
                          v-for="(tag, index) in scope.row.tags"
                          :key="index"
                          :label="tag"
                          :value="tag"
                        ></el-option>
                      </el-select>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </el-card>
          </div>

          <div style="display: inline-block">
            <div class="persona-body-item">
              <div style="margin-bottom: 10px">Actions</div>
              <el-card>
                <div style="display: flex; gap: 50px">
                  <div>
                    <span>Before action</span>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="Action to be performed before the bot's response."
                      placement="right"
                    >
                      <i class="el-icon-info" style="color: grey; margin-left: 5px"></i>
                    </el-tooltip>
                    <el-card style="margin: 20px auto">
                      <EventNodeSelector :value="beforeBotAction" :isGenAIModule="true" />
                    </el-card>
                  </div>

                  <div>
                    <span>After action</span>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="Action to be performed after the bot's response."
                      placement="right"
                    >
                      <i class="el-icon-info" style="color: grey; margin-left: 5px"></i>
                    </el-tooltip>
                    <el-card style="margin: 20px auto">
                      <EventNodeSelector :value="afterBotAction" :isGenAIModule="true" />
                    </el-card>
                    <span>Hide bot response</span>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="Ignore the bot's response render on after action avaiable."
                      placement="right"
                    >
                      <i class="el-icon-info" style="color: grey; margin-left: 5px"></i>
                    </el-tooltip>
                    <el-switch
                      v-model="selectedPersona.ignoreChatOnAfterBot"
                      style="margin-left: 20px"
                    />
                  </div>
                </div>
              </el-card>
            </div>
            <div class="persona-body-item">
              <div style="margin-bottom: 10px">Plugins</div>
              <el-card style="border: 1px solid #ebeef5">
                <el-table :data="tableFormatPlugins" border>
                  <!-- add column with is a checkbox for enable/disable -->
                  <el-table-column label="Enable" width="100">
                    <template slot-scope="scope">
                      <el-checkbox v-model="scope.row.enabled" />
                    </template>
                  </el-table-column>
                  <el-table-column label="Name">
                    <template slot-scope="scope">
                      <span style="text-transform: capitalize">{{ scope.row.name }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" width="150">
                    <template slot-scope="scope">
                      <!-- <el-switch v-model="scope.row.enabled" /> -->
                      <el-button
                        v-if="scope.row.config"
                        type="primary"
                        size="mini"
                        @click="showModal(scope.row)"
                        >Configure</el-button
                      >
                    </template>
                  </el-table-column>
                </el-table>
              </el-card>
            </div>
          </div>
        </div>
      </div>
    </div>
    <component
      v-if="selectedModal"
      :is="selectedModal"
      :dialogVisible="dialogVisible"
      :selectedPlugin="selectedPlugin"
      @updateDialogVisible="updateDialogVisible"
      @updatePluginConfig="updatePluginConfig"
    />
  </div>
</template>

<script>
import {
  DEFAULT_PERSONA_PROMPT,
  DEFAULT_PERSONA_MODEL,
  PERSONA,
  DEFAULT_PERSONA_NAME,
} from "../constants";
import EventNodeSelector from "@/components/EventNodeSelector/Index";
import JiraModal from "../Plugins/JiraModal.vue";

export default {
  data() {
    return {
      saving: false,
      selectedPersonaIndex: 0,
      newPersonaName: "",
      showNewPersonaPopover: false,
      selectedTags: {},
      selectedPersona: {},
      beforeBotAction: {
        event: "",
        data: {},
        side_effects: Array,
      },
      afterBotAction: {
        event: "",
        data: {},
        side_effects: Array,
      },
      selectedModel: DEFAULT_PERSONA_MODEL,
      selectedChatService: "OPENAI",
      unSavedChanges: [],
      tempPersonas: [],
      initPlugins: [
        {
          name: "jira",
          enabled: false,
          config: {
            apiKey: "",
            email: "",
            url: "",
          },
        },
        {
          name: "bing",
          enabled: false,
        },
      ],
      tableFormatPlugins: [],
      dialogVisible: false,
      selectedModal: null,
      selectedPlugin: null,
    };
  },
  components: {
    EventNodeSelector,
    JiraModal,
  },
  computed: {
    modules: {
      get() {
        return this.$store.state.modules;
      },
      set(value) {
        this.$store.state.modules = value;
      },
    },
    settings() {
      return this.$store.state.modules.genai;
    },
    document_tags() {
      return this.$store.state.modules.genai?.knowledge_document?.tags || [];
    },
    sortedPersonaList() {
      // Sort persona list by name in ascending order but keep the default persona at the top
      return this.modules.genai.personas.sort((a, b) => {
        if (a.name === DEFAULT_PERSONA_NAME) {
          return -1;
        }
        if (b.name === DEFAULT_PERSONA_NAME) {
          return 1;
        }
        return a.name.localeCompare(b.name);
      });
    },
    isDefaultPersona() {
      return this.selectedPersona.name === DEFAULT_PERSONA_NAME;
    },
  },
  methods: {
    saveChanges({ event = "update" }) {
      this.saving = true;

      this.selectedPersona.pluginConfig = this.tableFormatPlugins.reduce((acc, plugin) => {
        if (!plugin.config) {
          acc[plugin.name] = {
            enabled: plugin.enabled,
          };
          return acc;
        }

        acc[plugin.name] = {
          enabled: plugin.enabled,
          config: plugin.config,
        };
        return acc;
      }, {});

      if (event === "update") {
        this.modules.genai.personas[this.selectedPersonaIndex] = this.selectedPersona;
        this.unSavedChanges = this.unSavedChanges.filter(
          (index) => index !== this.selectedPersonaIndex
        );
      }

      this.$store
        .dispatch("SAVE_MODULES", {
          modules: this.modules,
        })
        .then(
          () => {
            this.saving = false;
            this.$message({
              type: "success",
              message: "Config Saved",
            });
          },
          () => {
            this.saving = false;
            this.$message({
              type: "error",
              message: "Error saving config",
            });
          }
        );
    },
    create() {
      // check if the persona name is not empty and not already exist
      const isValid =
        this.newPersonaName &&
        !this.tempPersonas.some((persona) => persona.name === this.newPersonaName);

      if (isValid) {
        this.modules.genai.personas.push({
          name: this.newPersonaName,
          prompt: DEFAULT_PERSONA_PROMPT,
          tags: {},
          beforeBotAction: {
            event: "",
            data: {},
            side_effects: Array,
          },
          afterBotAction: {
            event: "",
            data: {},
            side_effects: Array,
          },
          modelId: DEFAULT_PERSONA_MODEL,
          chatService: "OPENAI",
          temperature: 0.2,
          streaming_response: true,
          pluginConfig: {
            bing: {
              enabled: false,
            },
            jira: {
              enabled: false,
              config: {
                apiKey: "",
                email: "",
                url: "",
              },
            },
          },
          chatHistoryLimit: 25,
        });
        this.selectedPersonaIndex = this.modules.genai.personas.length - 1;
        this.newPersonaName = "";
        this.showNewPersonaPopover = false;
        this.saveChanges({
          event: "create",
        });
        this.tempPersonas = _.cloneDeep(this.sortedPersonaList);
      } else {
        this.$notify({
          title: "Error",
          message: "Persona name is empty or already exist",
          type: "error",
        });
      }
    },
    openUploadDialog() {
      this.uploadDialogVisible = true;
    },
    isSelectedTagContains(categoryName) {
      return _.has(this.selectedTags, categoryName);
    },
    isPersonaTagsContain(categoryName) {
      return _.has(this.selectedPersona.tags, categoryName);
    },
    handleTagChange(itemName, value) {
      if (value) {
        this.$set(this.selectedTags, itemName, []);
        this.$set(this.selectedPersona.tags, itemName, []);
      } else {
        this.$delete(this.selectedTags, itemName);
        this.$delete(this.selectedPersona.tags, itemName);
      }
    },
    handleChangePersonaTagName(value) {
      this.selectedPersona.tags["persona"][0] = value;
    },
    selectPersona() {
      const persona = this.tempPersonas[this.selectedPersonaIndex];
      if (!persona.tags) {
        persona.tags = {};
      }
      this.selectedTags = _.cloneDeep(persona.tags);

      if (!persona.beforeBotAction) {
        persona.beforeBotAction = {
          event: "",
          data: {},
          side_effects: Array,
        };
      }

      if (!persona.afterBotAction) {
        persona.afterBotAction = {
          event: "",
          data: {},
          side_effects: Array,
        };
      }

      if (!persona.pluginConfig || !persona.pluginConfig.jira || !persona.pluginConfig.bing) {
        persona.pluginConfig = {
          bing: {
            enabled: false,
          },
          jira: {
            enabled: false,
            config: {
              apiKey: "",
              email: "",
              url: "",
            },
          },
        };

        this.tableFormatPlugins = this.initPlugins;
      } else {
        this.tableFormatPlugins = Object.keys(persona.pluginConfig).map((key) => {
          if (!persona.pluginConfig[key].config) {
            return {
              name: key,
              enabled: persona.pluginConfig[key].enabled,
            };
          }
          return {
            name: key,
            enabled: persona.pluginConfig[key].enabled,
            config: persona.pluginConfig[key].config,
          };
        });
      }

      if (!persona.chatHistoryLimit) {
        persona.chatHistoryLimit = 25;
      }

      this.selectedPersona = persona;
      this.beforeBotAction = persona.beforeBotAction;
      this.afterBotAction = persona.afterBotAction;
    },
    setSelectPersona(index) {
      this.selectedPersonaIndex = index;
    },
    handleDeletePersona() {
      this.$confirm("Are you sure you want to delete this persona?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          const index = this.selectedPersonaIndex;

          if (
            index === 0 ||
            this.modules.genai.personas[index].name === DEFAULT_PERSONA_NAME ||
            this.modules.genai.personas.length === 1
          ) {
            this.$notify({
              title: "Error",
              message: "Cannot delete the default persona",
              type: "error",
            });
            return;
          }

          this.modules.genai.personas.splice(index, 1);
          this.tempPersonas = _.cloneDeep(this.sortedPersonaList);
          this.saveChanges({ event: "delete" });
          this.selectedPersonaIndex = 0;
        })
        .catch(() => {});
    },
    handleTagSelect(itemName, value) {
      this.$set(this.selectedTags, itemName, value);
    },
    showModal(plugin) {
      this.selectedPlugin = plugin;
      switch (plugin.name) {
        case "jira":
          this.selectedModal = JiraModal;
          break;
      }
      this.dialogVisible = true;
    },
    updateDialogVisible(value) {
      this.dialogVisible = value;
      if (!value) {
        this.selectedModal = null;
        this.selectedPlugin = null;
      }
    },
    updatePluginConfig(config) {
      this.selectedPlugin.config = config;
      this.dialogVisible = false;
    },
  },
  created() {
    const personas = _.get(this.modules.genai, PERSONA, []);

    if (personas.length === 0) {
      this.$set(this.modules.genai, PERSONA, [
        {
          prompt: DEFAULT_PERSONA_PROMPT,
          name: DEFAULT_PERSONA_NAME,
          tags: {},
          streaming_response: true,
          ignoreChatOnAfterBot: true,
          beforeBotAction: {
            event: "",
            data: {},
            side_effects: Array,
          },
          afterBotAction: {
            event: "",
            data: {},
            side_effects: Array,
          },
          temperature: 0.2,
          modelId: DEFAULT_PERSONA_MODEL,
          chatService: "OPENAI",
          pluginConfig: {
            bing: {
              enabled: false,
            },
            jira: {
              enabled: false,
              config: {
                apiKey: "",
                email: "",
                url: "",
              },
            },
          },
          chatHistoryLimit: 25,
        },
      ]);
      this.saveChanges();
    }
    this.tempPersonas = _.cloneDeep(personas);
    this.selectPersona();
  },
  watch: {
    selectedPersonaIndex: function (value) {
      this.selectPersona();
    },
    // Watch for tempPersonas changes and update the unSavedChanges array with the index of the changed persona
    tempPersonas: {
      handler: function (value) {
        this.unSavedChanges = [];
        // Sorted the value ASC with default persona in the top to compare with sortedPersonaList
        const sortedTemPersonas = _.cloneDeep(value).sort((a, b) => {
          if (a.name === DEFAULT_PERSONA_NAME) {
            return -1;
          }
          if (b.name === DEFAULT_PERSONA_NAME) {
            return 1;
          }
          return a.name.localeCompare(b.name);
        });

        for (let i = 0; i < sortedTemPersonas.length; i++) {
          if (JSON.stringify(sortedTemPersonas[i]) !== JSON.stringify(this.sortedPersonaList[i])) {
            this.unSavedChanges.push(i);
          }
        }
      },
      deep: true,
    },
    selectedTags: {
      handler: function (value) {
        // remove empty tags from selectedTags and update the selectedPersona tags
        for (const key in value) {
          if (value[key].length !== 0) {
            this.tempPersonas[this.selectedPersonaIndex].tags[key] = value[key];
          }
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
#persona-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  .save-button {
    width: 120px !important;
  }
}

#persona-body {
  display: flex;

  .persona-body-left {
    border: 1px solid #ebeef5;
    border-right: none;
    width: 15%;
  }

  .persona-body-right {
    border: 1px solid #ebeef5;
    width: 85%;
    padding: 20px;
  }

  .persona-body-item {
    margin-bottom: 20px;
  }
}

.memory-bias-slider {
  width: 30%;
  display: flex;
  align-items: center;

  span {
    font-size: 14px;
  }
}

.persona-list {
  padding: 0;
  margin: 0;
  .persona-item {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #ebeef5;
    list-style: none;
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    word-break: break-all;

    &:hover {
      background-color: #f5f7fa;
    }

    &.selected {
      background-color: #f5f7fa;
    }

    .persona-delete-button {
      color: red;
    }
  }
}
</style>
